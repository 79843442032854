import { types } from './actions'

const initialState = {
    isSticky: false,
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.TOGGLE_STICKY_FMD:
            return { ...state, isSticky: action?.payload?.isSticky }

        default:
            return state
    }
}

export default appReducer
