// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import Loadable from 'react-loadable'

// Components
const TopBar = Loadable({
    loader: () => import('src/router/Wrapper/components/TopBar'),
    loading: () => null,
})
const NavBar = Loadable({
    loader: () => import('src/router/Wrapper/components/NavBar'),
    loading: () => null,
})
const PageHeader = Loadable({
    loader: () => import('src/router/Wrapper/components/PageHeader'),
    loading: () => null,
})
const Footer = Loadable({
    loader: () => import('src/router/Wrapper/components/Footer'),
    loading: () => null,
})
const ScrollToTop = Loadable({
    loader: () => import('src/router/Wrapper/components/ScrollToTop'),
    loading: () => null,
})
const MsgFacebook = Loadable({
    loader: () => import('src/router/Wrapper/components/MsgFacebook'),
    loading: () => null,
})

// Styles
import styles from './styles.module.scss'

const withWrapper = (WrappedComponent) => {
    const MyComponent = (props) => {
        const { match } = props
        return (
            <div className={styles['wrapper']}>
                <TopBar {...props} />
                <NavBar {...props} />
                {match.path !== '/' && <PageHeader {...props} />}
                <WrappedComponent {...props} />
                <Footer {...props} />
                <ScrollToTop />
                <MsgFacebook />
            </div>
        )
    }

    MyComponent.propTypes = {
        match: PropTypes.object,
    }

    return MyComponent
}

export default withWrapper
