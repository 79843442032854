// Libraries
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'

// Reducer
import appReducer from 'store/App/reducer'

// Redux-Saga
import rootSaga from 'store/middleware'

const rootReducer = combineReducers({
    App: appReducer,
})

let store = null
const sagaMiddleware = createSagaMiddleware()
const env = 'development'

if (env === 'development') {
    const composeEnhancers =
        typeof window === 'object' && window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
            ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({
                  trace: true,
                  traceLimit: 25,
              })
            : compose

    store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))
} else {
    store = createStore(rootReducer, applyMiddleware(sagaMiddleware))
}

sagaMiddleware.run(rootSaga)

export default store
