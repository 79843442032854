// Libraries
import Loadable from 'react-loadable'
import withWrapper from 'src/router/Wrapper'

// Containers
const About = Loadable({
    loader: () => import('layouts/About'),
    loading: () => null,
})
const Service = Loadable({
    loader: () => import('layouts/Service'),
    loading: () => null,
})
const DetailService = Loadable({
    loader: () => import('layouts/Home/components/Services/components/DetailService'),
    loading: () => null,
})
const Team = Loadable({
    loader: () => import('layouts/Team'),
    loading: () => null,
})
const Project = Loadable({
    loader: () => import('layouts/Project'),
    loading: () => null,
})
const DetailProject = Loadable({
    loader: () => import('layouts/Project/components/DetailProject'),
    loading: () => null,
})
const Blog = Loadable({
    loader: () => import('layouts/Blog'),
    loading: () => null,
})
const Post = Loadable({
    loader: () => import('layouts/Post'),
    loading: () => null,
})
const Contact = Loadable({
    loader: () => import('layouts/Contact'),
    loading: () => null,
})
const Home = Loadable({
    loader: () => import('layouts/Home'),
    loading: () => null,
})

export default [
    {
        state: 'main.about',
        path: '/about',
        exact: true,
        component: withWrapper(About),
    },
    {
        state: 'main.service',
        path: '/service',
        exact: true,
        component: withWrapper(Service),
    },
    {
        state: 'main.service.detail',
        path: '/service/:serviceId',
        exact: true,
        component: withWrapper(DetailService),
    },
    {
        state: 'main.team',
        path: '/team',
        exact: true,
        component: withWrapper(Team),
    },
    {
        state: 'main.project',
        path: '/project',
        exact: true,
        component: withWrapper(Project),
    },
    {
        state: 'main.project.detail',
        path: '/project/:projectId',
        exact: true,
        component: withWrapper(DetailProject),
    },
    {
        state: 'main.blog',
        path: '/blog',
        exact: true,
        component: withWrapper(Blog),
    },
    {
        state: 'main.post',
        path: '/post',
        exact: true,
        component: withWrapper(Post),
    },
    {
        state: 'main.contact',
        path: '/contact',
        exact: true,
        component: withWrapper(Contact),
    },
    {
        state: 'main',
        path: '/',
        exact: false,
        component: withWrapper(Home),
    },
]
