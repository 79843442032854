import { takeEvery, put } from 'redux-saga/effects'

import { types } from './actions'

function* toggleSticky(action) {
    yield put({ type: types.TOGGLE_STICKY_FMD, payload: action.payload })
}

export function* appMiddleware() {
    yield takeEvery(types.TOGGLE_STICKY, toggleSticky)
}
