// Libraries
import React from 'react'
import { Provider } from 'react-redux'

// Store
import store from './store'

// Router
import Router from 'src/router'

// Styles
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'jquery/dist/jquery.min.js'
import 'assets/flaticon/font/flaticon.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './index.css'

const App = () => {
    return (
        <Provider store={store}>
            <Router />
        </Provider>
    )
}

export default App
