// Libraries
import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

// Layouts
import layouts from 'layouts'

const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                {layouts.map((route, routeIndex) => {
                    return route.component ? <Route key={routeIndex} path={route.path} exact={route.exact} component={route.component} /> : null
                })}
            </Switch>
        </BrowserRouter>
    )
}

export default Router
